<template>
  <div class="mt-4 card biz-primary">
    <div class="card-header font-weight-bold">
      {{$t('COMPANY_INFO.HEADER_BASIC_INFO')}}
      <div v-if="!previewMode" class="control">
        <router-link to="/supplier/manage/company-info/edit">{{$t('COMMON.EDIT')}}</router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="biz-form-group">
            <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.APPLICATION_TYPE')}}</label>
            <div class="biz-form-body">
              
              <p class="biz-offset">{{
                company.basic.applicationType == 1 ? $t('COMPANY_INFO.CORPORATE') : 
                company.basic.applicationType == 2 ? $t('COMPANY_INFO.SELF_EMPLOYED') :
                $t('COMMON.NOT_REGISTERED')
              }}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="biz-form-group">
            <label class="biz-form-label font-weight-bold">
              {{company.basic.applicationType == 1
                ? $t('COMPANY_INFO.CORPORATION_NUMBER')
                : $t('COMPANY_INFO.REGISTER_NUMBER')}}
            </label>
            <div class="biz-form-body">
              <p class="biz-offset">
                {{company.basic.applicationType == 2 ? 'T' : ''}}
                {{company.basic.corporationNumber || $t('COMMON.NOT_REGISTERED')}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="biz-form-group">
            <label class="biz-form-label font-weight-bold">
              {{company.basic.applicationType == 1 ? $t('COMPANY_INFO.COMPANY_NAME'): $t('COMPANY_INFO.CEO')}}
            </label>
            <div class="biz-form-body">
              <p class="biz-offset">{{company.basic.fullName ? company.basic.fullName + '（' + (company.basic.nameKana || $t('COMMON.NOT_REGISTERED')) + '）' : $t('COMMON.NOT_REGISTERED')}}</p>
              <p v-if="tsr && tsr.full_name === false" class="biz-offset text-danger">{{$t('COMPANY_INFO.TSR_FULL_NAME_ERROR_MESSAGE')}}</p>
            </div>
          </div>
        </div>
        <div class="col"></div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="biz-form-group">
            <label class="biz-form-label font-weight-bold">{{ company.applicationType == 2 ?
              $t('COMPANY_INFO.REGISTERED_ADDRESS'):
              $t('COMPANY_INFO.COMPANY_ADDRESS')}}
              </label>
            <div class="biz-form-body">
              <p class="biz-offset">{{company.basic.postalCode ? "〒" + company.basic.postalCode:$t('COMMON.NOT_REGISTERED')}}</p>
              <p class="biz-offset">
                {{company.basic.address1 ? company.basic.address1 + company.basic.address2 : $t('COMMON.NOT_REGISTERED')}}
              </p>
            </div>
          </div>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["company", "tsr", "previewMode"]
}
</script>

<style>

</style>