<template>
  <div>
    <CompanyBasicView :company="formData.company" :previewMode="true"/>

    <UserInfoView :previewMode="true" :mainUser="formData.main" :subs="formData.subs"/>
    
    <div class="mt-4 card biz-primary" v-if="!$store.state.fromPortal && $store.state.user.status.openInvited">
      <div class="card-header font-weight-bold">
        OPENサプライヤへの案内
      </div>
      <div class="card-body">
        <div class="biz-form-group">
          <label class="biz-form-label">OPENサプライヤへの案内</label>
          <div class="biz-form-body">
            <p class="biz-offset" v-if="formData.company.basic.isOpenSupplier">OPENサプライヤになる</p>
            <p v-if="!formData.company.basic.isOpenSupplier">OPENサプライヤにならない</p>
          </div>
        </div>
      </div>
    </div>    

    <div class="text-center mt-5">
      <p>{{$t('WIZARD.NEXT_DOUBLE_CHECK_MESSAGE')}}</p>
      <j-button variant="primary" size="lg" v-on:click="onSave">
        {{$t('COMMON.NEXT')}}
      </j-button>
      <div class="mt-4">
        <j-button variant="light" to="/initiate/registration/company-info">
          <font-awesome-icon icon="arrow-left"></font-awesome-icon>
          {{$t('COMMON.GO_BACK')}}
        </j-button>
      </div>
    </div>

  </div>
</template>

<script>

import CompanyInfoModel from '@/model/CompanyInfo';
import UserInfo from '@/model/UserInfo';
import Init from '@/model/Init';
import CompanyBasicView from '@/components/common/CompanyBasicView.vue';
import UserInfoView from '@/components/common/UserInfoView.vue';

export default {
  components: { CompanyBasicView, UserInfoView },
  props: ['formData'],
  created: function() {
    if(!this.formData.company 
      || !this.formData.main
      || !this.formData.subs ){
      
      this.$router.push({
        path: "/initiate/registration",
        params: {}
      });
    }
  },
  methods: {
    onSave(){
      if(this.$store.state.fromPortal){
        this.formData.company.basic.isOpenSupplier = true;
      }
      CompanyInfoModel.saveCompanyBasicInfo( this.$store.state.user.companyCode, this.formData.company.basic).then(
        (result) => {
          UserInfo.save(result.data.message, {
            main: this.formData.main,
            subs: this.formData.subs.filter(s => s.name)
          }).then(()=> {
            this.$store.commit("setInitiateForm", {});
            this.$store.commit("updateUserName", { data: {
              name: this.formData.main.name,
              organizationName: this.formData.main.organizationName
            }, 
            cb: () => {
              this.$successMessage("営業担当情報の登録が完了しました。");
              Init.getRoles().then(result => {
                this.$store.commit("continueToDashboard", { roles: result.data, cb: () => {
                  if(this.$store.state.user.status.spotSupplier === true){
                    this.$router.push('/supplier/manage');  
                  }else this.$router.push('/supplier/registration');
                }});
              }).catch(reason => this.$errorMessage(undefined, {reason}));
            }});
          }).catch(reason => this.$errorMessage(undefined, {reason}));
        }).catch(reason => this.$errorMessage(undefined, {reason}));
    }
  }
}
</script>

<style>

</style>